@import 'styles/vars';

.link {
  display: flex;
  align-items: center;
  white-space: nowrap;
  line-height: 1;
  text-decoration: none;

  @media (max-width: $w992) {
    margin-top: 0.6875rem;
  }

  span {
    color: $secondary;
    line-height: 1;
    transition: color $ease-in;
    margin-right: 0.5rem;
  }

  svg {
    g {
      transition: opacity $ease-in;
    }
  }

  &:hover {
    span {
      color: $primary;
    }

    svg g {
      opacity: 1;
    }
  }
}
