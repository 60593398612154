@import 'styles/vars';

.container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 0 $marg24 0 0;
  margin: 0;
  list-style: none;

  @media (max-width: $w1400) {
    padding: 0;
  }
}
