@import 'styles/vars';

.link {
  font-size: $f14;
  line-height: 1;
  white-space: nowrap;
  color: $secondary;
  text-decoration: none;
  margin: 0 auto;
  display: block;

  @media (max-width: $w1400) {
    margin: 1rem 0 0;
  }
}
