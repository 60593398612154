html {
  min-height: 100vh;
  box-sizing: border-box;
  font-family: 'Graphik LC Web', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: #000;
  background: #fff;
  line-height: 1.5;
}

/* ios body flickering fix */
body.ReactModal__Body--open {
  right: 0;
}

.brown-link {
  color: #af704d;
  text-decoration: none;
  transition: color 0.2s ease-in;
}

.brown-link:hover {
  color: #000;
}

.grecaptcha-badge {
  opacity: 0;
  visibility: hidden;
}

.b24-widget-button-wrapper.b24-widget-button-visible {
  z-index: 3;
}

.b24-widget-button-show~.b24-widget-button-wrapper {
  z-index: 10150;
}

.b24-widget-button-openline_livechat {
  display: none !important;
}