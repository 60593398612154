@import '../../../styles/scss/mixins/fluid-font-size.scss';
@import 'styles/vars.scss';

.wrapper {
  margin: 1.25rem 0 2.5rem;
}

.title {
  font-weight: 400;
  margin: 0;
  @include fluid-font-size(1.75rem, 2rem, 2.25rem);
}

.text {
  margin: 0.375rem 0 0;
  color: $secondary;
}

.action {
  padding: 0;
  background: none;
  border: none;
  color: $primary;
  cursor: pointer;
  transition: $ease-in;

  &:hover {
    color: $brown;
    transition: $ease-out;
  }
}
