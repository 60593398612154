@import '../../../styles/vars.scss';

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: $f18;
  line-height: 1.5;
  color: $primary;

  &.uppercase {
    text-transform: uppercase;
  }

  &.letters {
    letter-spacing: 0.1em;
  }

  @media (max-width: $w992) {
    font-size: $marg16;

    &.big {
      svg {
        width: 0.875rem;
        height: 0.8125rem;
      }
    }
  }

  svg {
    transition: transform $ease-in;
  }

  &.active {
    svg {
      transform: rotate(45deg);
    }
  }
}

.description {
  margin-bottom: $marg24;
  font-size: $f18;

  p:first-child {
    margin-top: 0 !important;
  }
}
