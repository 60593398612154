@import 'styles/vars';

.container {
  display: flex;
  flex-direction: column;

  dl,
  dd {
    margin: 0;
    padding: 0;
  }
}

.title {
  font-size: $f18;
  line-height: 1.3;
  color: $secondary;
  text-transform: lowercase;

  @media (max-width: $w1200) {
    font-size: $f16;
  }
}

.list {
  margin: 0.3125rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.item {
  list-style: none;
  display: flex;
  margin-top: 0.625rem;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    span {
      color: $brown;
    }
  }

  span {
    font-size: $f24;
    line-height: 1.25;
    color: $primary;
    text-transform: lowercase;
    transition: color $ease-in;

    @media (max-width: $w1400) {
      font-size: $f20;
    }
    @media (max-width: $w1200) {
      font-size: $f18;
    }
  }
}

.gradient {
  background: linear-gradient(
    86deg,
    #7852bd -40.52%,
    #e94f9b 30.69%,
    #f0a349 105.93%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
