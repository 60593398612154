@import '../../../styles/vars.scss';

.wrapper {
  padding-top: 6.25rem;
  flex-grow: 1;

  @media (max-width: $w992) {
    padding-top: 4.375rem;
  }

  &.noPadd {
    padding: 0 !important;
  }
}
