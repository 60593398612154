@import 'styles/vars';

.container {
  width: 100%;
  flex-shrink: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 3.125rem;

  @media (max-width: $w992) {
    border-top: none;
  }
}

.body {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: $w992) {
    flex-direction: column;
    flex-wrap: wrap;
  }
}

.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
  padding-bottom: 5rem;
  width: 100%;

  @media (max-width: $w1400) {
    align-items: flex-start;

    .left {
      padding-top: 0.5rem;
    }
  }

  @media (max-width: $w992) {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 3.75rem;
    padding-bottom: 2.8125rem;
  }
}

.left {
  max-width: 25rem;

  @media (max-width: $w992) {
    max-width: 100%;
    width: 100%;
  }
}

.left__title {
  font-size: $f36;
  line-height: 1.3;
  text-transform: lowercase;
  margin: 0;

  @media (max-width: $w1400) {
    font-size: $f26;
  }
  @media (max-width: $w1200) {
    font-size: $f20;
  }
}

.right {
  display: flex;
  width: 33.333%;
  justify-content: space-between;
  align-items: center;
  min-width: 37.5rem;

  @media (max-width: $w1400) {
    align-items: flex-start;
  }

  &__info {
    width: 100%;
    display: flex;

    @media (max-width: $w1400) {
      display: block;
      padding-top: 0.5rem;

      a {
        display: block;
      }
    }
  }

  &.withJulirea {
    min-width: 47.5rem;
  }

  @media (max-width: $w1400) {
    min-width: 31.25rem;
    &.withJulirea {
      min-width: 38.75rem;
    }
  }

  @media (max-width: $w1200) {
    width: 50%;
    min-width: 31.25rem;

    &.withJulirea {
      min-width: 38.75rem;
    }
  }
  @media (max-width: $w992) {
    width: 100%;
    min-width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: $marg16;

    &.withJulirea {
      min-width: 100%;
    }

    &_descktop {
      display: none;
    }
  }
}

.socnets {
  display: flex;
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;

  li:not(:last-child) {
    margin-right: 1rem;
  }

  a {
    position: relative;
    width: 3.125rem;
    height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $w576) {
      width: 2.25rem;
      height: 2.25rem;
    }

    &:hover {
      svg path {
        fill: $brown;
        transition: fill 0.2s linear;
      }
    }
  }
}

.border {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  :global(.border-brown) {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
  }

  :global(.border-black) {
    stroke-opacity: 1;
  }

  &:hover {
    :global(.border-brown) {
      transition: stroke-dashoffset 1s linear;
      stroke-dashoffset: 0;
    }
    :global(.border-black) {
      stroke-opacity: 0;
    }
  }
}
