@import 'styles/vars';

.container {
  display: flex;
  margin-left: auto;
  flex-grow: 1;

  &__jul {
    margin-left: 0;
    flex-grow: initial;

    @media (max-width: $w1080) {
      margin-left: auto;
      flex-grow: 1;
    }
  }
}

.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}
