@import 'styles/vars';

.container {
  z-index: 2;
  position: absolute;
  top: $head-size;
  left: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-0.625rem);
  transition: $ease-in;

  display: flex;
  width: 100%;
  background-color: $light;
  height: 5.625rem;

  @media (max-width: $w992) {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    pointer-events: auto;
    transform: none;
    background-color: transparent;
    height: 3.75rem;
    border-top: 0.0625rem rgba(255, 255, 255, 0.2) solid;
    border-bottom: 0.0625rem rgba(255, 255, 255, 0.2) solid;
    opacity: 1;
  }

  &.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    transition: $ease-out;
  }
}

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-top: 0.0625rem rgba(0, 0, 0, 0.1) solid;

  @media (max-width: $w992) {
    border-top: none;
  }
}

.field {
  display: flex;
  align-items: center;
  height: 2.9375rem;
  width: 100%;
}

.input {
  display: flex;
  align-items: center;
  border: 0 !important;
  outline: 0 !important;
  font-size: $f36;
  background-color: inherit;
  height: 100%;
  line-height: 1;
  text-transform: uppercase;
  width: 100%;
  margin-right: 2rem;

  @media (max-width: $w992) {
    font-size: $f16;
    color: $white;
    margin-right: 0;

    &:first-child {
      display: none;
    }
  }

  &_mobile {
    display: none;
    width: 100%;
    padding: 0 1rem 0 0;

    @media (max-width: $w992) {
      display: flex;
    }
  }
}

.link {
  color: $brown;

  &:hover {
    color: $primary;
  }

  svg {
    display: none;

    path {
      fill: $white;
    }
  }

  @media (max-width: $w992) {
    span {
      display: none;
    }

    svg {
      display: block;
    }
  }
}

.clear {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  margin-left: 2rem;

  @media (max-width: $w992) {
    display: none;
  }
}
