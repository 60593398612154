@import '../../../styles/vars.scss';

.wrapper {
  position: fixed;
  bottom: 1.25rem;
  right: 2.1875rem;
  z-index: 6;
  background: $red;
  box-shadow: 0px -12px 44px $gray;
  padding: 1.25rem 1.25rem 1.875rem 1.5625rem;
  color: $white;
  font-size: $f14;
  width: 100%;
  max-width: 23.4375rem;
  opacity: 0;
  display: none;
  transition: opacity 0.1s linear;

  &:global(.error-fade-enter),
  &:global(.error-fade-enter-active) {
    display: block;
  }

  &:global(.error-fade-enter-done) {
    opacity: 1;
    display: block;
  }

  @media (max-width: $w768) {
    max-width: none;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.title {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: $f14;
  margin: 0;
}

.text {
  margin: 1rem 0 0;
}

.close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  background: none;
  border: none;
  cursor: pointer;
}
