@import 'styles/vars';

.maxWidth {
  width: 100%;
  margin: 0 auto;
  padding-left: $padd;
  padding-right: $padd;

  &.limited {
    max-width: 86.25rem + ($padd * 2);
  }

  &.mobile_wide {
    @media (max-width: $w992) {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.limited {
    max-width: 86.25rem + ($padd * 2);
  }

  @media (max-width: $w1200) {
    padding-left: $padd-medium;
    padding-right: $padd-medium;
  }

  @media (max-width: $w768) {
    padding-left: $padd-small;
    padding-right: $padd-small;
  }
}
