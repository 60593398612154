@import 'styles/vars';

.nav {
  list-style: none;
  margin: 0;
  padding: 0;
}
.menu {
  margin: 3.125rem 0 0;
  columns: 2 auto;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  font-size: $f16;
  line-height: 1.25;
  color: rgba(255, 255, 255, 0.7);
  padding: 0.5rem 0;
  text-decoration: none;
}

.accordion {
  display: block;
  margin-top: 1.875rem;
  svg path {
    fill: $white;
    transition: fill $ease-in;
  }
}

.active {
  .title_link {
    color: $brown;
  }

  svg path {
    fill: $brown;
  }
}

.title_link {
  color: $white;
  font-size: $f22;
  max-width: calc(100% - 2.5rem);
  text-transform: lowercase;
  transition: color $ease-in;
}

.items {
  display: grid;
  grid-template-columns: 40% 40%;
  grid-gap: 1.5rem 11%;
  padding: 0 0 1.25rem;
  overflow-x: hidden;

  @media (max-width: $w576) {
    grid-gap: 1.5rem 13%;
  }

  a {
    color: $white;
  }
}

.link {
  display: block;
  font-size: $f16;
  line-height: 1.25;
  color: $white;
  text-decoration: none;
}

.accordionDescription {
  padding-top: 1.5rem;
  margin-bottom: 0;
}

.julirea {
  background: $white05t;

  &__inner {
    position: relative;
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }

  &__title {
    font-size: $f22;
    color: $white;
    transition: color $ease-in;
  }

  &__accordion {
    svg path {
      fill: $white;
      transition: fill $ease-in;
    }

    .active {
      .julirea__title {
        color: $brown;
      }

      svg path {
        fill: $brown;
      }
    }
  }

  &__topTicker,
  &__bottomTicker {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    font-size: 0.6875rem;
    line-height: 1;
    color: $tickerColorMobile;
  }

  &__topTicker {
    top: 0;
  }

  &__bottomTicker {
    bottom: 0.3125rem;
  }

  .items {
    padding-bottom: 0;
  }

  .accordionDescription {
    margin-bottom: 0.625rem;
  }
}

.gradient {
  background: linear-gradient(
    86deg,
    #7852bd -40.52%,
    #e94f9b 30.69%,
    #f0a349 105.93%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
