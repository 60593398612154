@import 'styles/vars';

.container {
  width: 25rem;
  display: flex;
  flex-direction: column;

  @media (max-width: $w1400) {
    width: 18.75rem;
  }
  @media (max-width: $w1200) {
    width: 14.0625rem;
  }

  @media (max-width: $w768) {
    width: 100%;
  }
}

.title {
  font-size: $f36;
  line-height: 1.3;
  text-transform: lowercase;

  @media (max-width: $w1400) {
    font-size: $f26;
  }
  @media (max-width: $w1200) {
    font-size: $f20;
  }
}

.wrapper {
  position: relative;
  margin-top: 1.25rem;
}

.input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.75rem;
  border: none;
  border-bottom: 0.125rem solid $primary;
  border-radius: 0;
  padding-right: 1.25rem;

  &:focus {
    outline: none;
  }

  &.error {
    border-bottom-color: $red;
  }
  &.success {
    border-bottom-color: $green;
  }
}

.loader {
  margin-top: 1rem;
}

.error_message {
  color: $red;
  margin: 0;
  position: absolute;
  top: calc(100% + 0.125rem);
  font-size: $f14;
}

.success_message {
  color: $green;
}

.button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 17px;
  position: absolute;
  bottom: 1.125rem;
  right: 0;

  svg {
    transform: rotate(180deg);
  }
}

.field_wrapper {
  position: relative;
  margin-top: 2rem;
}

.privacy_error {
  position: absolute;
  top: calc(100% + 0.375rem);
  margin: 0;
  font-size: $f14;
}

.policy {
  color: inherit;
}

.notification {
  position: relative;
  margin-top: 1rem;
  padding: 1rem;
  color: $white;
  background-color: $red;

  p {
    margin: 0;
  }
}

.closeNotification {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
}
