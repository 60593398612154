@import '../../../../styles/vars.scss';

.list {
  margin: 4.5rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    border: 1px solid $white;
    border-radius: 50%;
    width: 3.125rem;
    height: 3.125rem;

    &:not(:last-child) {
      margin-right: 1.625rem;
    }
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  svg path {
    fill: $white;
  }
}
