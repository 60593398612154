@import '../../../styles/vars.scss';

.text {
  margin: 2.5rem 0 0;
  font-size: $f14;
  color: $secondary;

  a {
    color: inherit;
  }
}
