@import 'styles/vars';

.row {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.15rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid;
}

.back {
  background: transparent;
  border: none;
  cursor: pointer;
  transform: translate3d(-50%, 0, 0);

  svg {
    display: block;
  }
}

.text {
  margin: 0;
  font-size: $f20;
  vertical-align: middle;
}

.count {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  line-height: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
  background: $brown;
  color: $white;
  border-radius: 50%;
  margin-left: 0.625rem;
}
