.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
}

.header {
  margin: 1.15rem 0 0.5rem;
  font-weight: normal;
  line-height: 1.3;
}

.text {
  font-size: 1.25rem;
  margin: 0;
  opacity: 0.5;
}
