.wrapper {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &.errorPage {
    & > main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
    }
  }
}
