@import 'styles/vars';

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  display: flex;
  align-items: flex-start;
  padding: 1.875rem 2.15rem;
  border-bottom: 1px solid $primary;
}

.text {
  flex-grow: 1;
}

.delete {
  flex-shrink: 0;
  margin-left: 0.5rem;
  border: none;
  background: none;
  cursor: pointer;
}

.image {
  flex-shrink: 0;
  margin-right: 1.25rem;
}

.name {
  margin: 0 0 0.1em;
  font-size: $f18;
  color: $primary;
  line-height: 1;
}

.description {
  margin: 0 0 0.42em;
  font-size: $f14;
  color: $secondary;
}

.price {
  font-size: $f18;
  vertical-align: top;
  margin: 0.3125rem 0 0.625rem;
}

.discountPrice {
  color: $secondary;
  text-decoration: line-through;
  font-size: $f14;
}

@keyframes itemLoading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.loading {
  will-change: opacity;
  animation: itemLoading 1.5s ease-in-out 0s infinite;
}
