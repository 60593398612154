@import 'styles/vars';

.container {
  display: flex;
  flex-direction: column;

  dl,
  dd {
    margin: 0;
  }
}

.title {
  font-size: $f12;
  line-height: 1.3;
  color: $secondary;
  text-transform: uppercase;
  letter-spacing: 0$marg16;
}

.list {
  margin: 0.3125rem 0 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.item {
  list-style: none;
  display: flex;
  margin-top: $marg16;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;

  &:hover {
    span {
      color: $brown;
    }
  }

  span {
    font-size: $f18;
    line-height: 1.25;
    color: $primary;
    text-transform: lowercase;
    transition: color $ease-in;

    @media (max-width: $w1200) {
      font-size: $f16;
    }
  }

  &.active {
    span {
      color: $brown;
    }
  }
}
