@import 'styles/vars';

.container {
  z-index: 2;
  position: absolute;
  top: $head-size;
  left: 0;
  right: 0;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-0.625rem);
  transition: $ease-in;

  display: flex;
  width: 100%;
  background-color: $light;
  padding: 1.875rem 0 3.125rem;

  &.active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    transition: $ease-out;
  }

  @media (max-width: $w992) {
    display: none;
  }
}

.wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

.navs {
  display: flex;
  flex-wrap: nowrap;
  flex-grow: 1;
  justify-content: space-between;
  max-width: 53.125rem;
  padding: 0;
  margin: 0;

  @media (max-width: $w1400) {
    max-width: 43.75rem;
  }

  @media (max-width: $w1200) {
    max-width: 34.375rem;
  }
}

.contacts {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 auto;
  width: 12.5rem;
}

.contactsItem {
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 2.5rem;
  }
}

.contactsTitle {
  font-size: $f12;
  color: $secondary;
  text-transform: uppercase;
  letter-spacing: 0$marg16;
  line-height: 1;
  white-space: nowrap;

  &:not(:first-child) {
    margin-top: 1.875rem;
  }
}

.contactsValue {
  margin: 1.25rem 0 0;
  font-size: $f18;
  line-height: 1;
  white-space: nowrap;
  text-decoration: none;
  a {
    color: $primary;
  }
}
