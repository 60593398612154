@import 'styles/vars';

.link {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none !important;

  svg {
    pointer-events: none;
  }
}

.logo {
  @media (max-width: $w992) {
    display: none;
  }

  &_mobile {
    display: none;

    @media (max-width: $w992) {
      display: block;
    }
  }
}
