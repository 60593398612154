@import 'styles/vars';
.button {
  width: 100%;
  height: 3.25rem;
  background-color: $primary;

  &:hover {
    color: $white;
  }
}

@keyframes tableLoading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.loading {
  will-change: opacity;
  animation: tableLoading 1.5s ease-in-out 0s infinite;
}

.buttonContainer {
  position: sticky;
  bottom: 0;
  padding: 1rem 1.25rem;
  background-color: $white;
  box-shadow: 0 -0.625rem 0.75rem -0.5rem $black06t;
}

.dot {
  width: 0.1875rem;
  height: 0.1875rem;
  margin: 0 0.5rem;
  background-color: $white;
  border-radius: 50%;
}

@media (max-width: $w992) {
  .button {
    height: 3.125rem;
    font-size: $f14;
  }
}
