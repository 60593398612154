@import '../../../styles/vars.scss';

.title {
  line-height: 1.5;
  margin: 0 0 0.5rem;
}

.row {
  display: flex;
}

.link {
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid $blue50t;
  color: $blue;
  line-height: 1.5;
  padding: 0.75rem;
  transition: $ease-in;

  svg {
    width: 2.5rem;
  }

  circle {
    fill: $white;
  }

  &.loading,
  &.active,
  &:hover {
    color: $white;
    background-color: $blue;
    transition: $ease-out;
  }

  &.loading {
    pointer-events: none;
  }

  &:not(:last-child) {
    border-right: none;
  }
}
