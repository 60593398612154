.wrapper {
  position: relative;
}

.children {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
}

.hasChildren {
  padding-right: 6.5rem !important;
}

.hasIcon {
  padding-left: 3.75rem !important;
}

.icon {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  pointer-events: none;
}
