@import '../../../styles/vars.scss';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.125rem;
  width: fit-content;
  max-width: 100%;
  min-width: 21.875rem;
  padding: 0 1.625rem;
  cursor: pointer;

  font-weight: 400;
  font-size: $f18;
  letter-spacing: 0.1em;
  line-height: 1;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  color: $white;

  border: 0.0625rem solid $dark;
  background-color: $dark;

  transition: $ease-in;

  &:disabled {
    background: $gray;
    border-color: $gray;

    &:hover {
      border-color: $gray;
      background-color: $gray;
    }
  }
  svg.ellipseBorder {
    display: none;
  }

  &:hover {
    border-color: $brown;
    background-color: $brown;
  }

  @media (max-width: $w992) {
    font-size: $f16;
    min-width: unset;
  }
}

.black {
  border: 0.0625rem solid $primary;
  background-color: $primary;
}
.white {
  border: 0.0625rem solid $primary;
  background-color: $white;
  color: $primary;

  &:hover {
    color: $white;
  }
}

%ellipse {
  background-color: transparent;
  color: $primary;
  border: none;
  letter-spacing: unset;
  min-width: unset;
  position: relative;

  svg.ellipseBorder {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    :global(.border-brown) {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
    }

    :global(.border-black) {
      stroke-opacity: 1;
    }
  }

  &:hover {
    background-color: transparent;
    color: $brown;

    svg {
      position: absolute;
      width: 100%;
      height: 100%;

      :global(.border-brown) {
        transition: stroke-dashoffset 1s linear;
        stroke-dashoffset: 0;
      }
      :global(.border-black) {
        stroke-opacity: 0;
      }
    }
  }
}

.ellipse {
  @extend %ellipse;

  height: 3.75rem;
  padding: 0 1.625rem;
}

.ellipseMedium {
  @extend %ellipse;

  height: 5.625rem;
  padding: 0 1.4375rem;
  min-width: 13.125rem;

  @media (max-width: $w992) {
    height: 5rem;
    padding: 0 1.625rem;
    min-width: unset;
  }
}

.ellipseBig {
  @extend %ellipse;

  font-size: $f28;
  height: 7.1875rem;
  padding: 0 3.125rem;

  @media (max-width: $w992) {
    font-size: $f20;
    height: 5.375rem;
    padding: 0 2.25rem;
  }
}

.loading {
  pointer-events: none;
  circle {
    fill: $white;
  }
}

.white.loading {
  circle {
    fill: $primary;
  }
}
