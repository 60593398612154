@import '../../../styles/vars.scss';

.wrapper {
  position: relative;
  display: flex;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle {
  width: 2.5rem;
  height: 1.3125rem;
  border-radius: 1.125rem;
  position: relative;
  background: $black20t;
  transition: background $ease-in;
  margin-right: 0.625rem;
  cursor: pointer;
  flex-shrink: 0;

  &_inner {
    width: 1.0625rem;
    height: 1.0625rem;
    border-radius: 50%;
    background: $white;
    position: absolute;
    left: 0.125rem;
    top: 0.125rem;
    transition: left $ease-in;
  }
}

.checkbox:checked + .toggle {
  background: $primary;
}

.checkbox:checked + .toggle .toggle_inner {
  left: calc(100% - 1.1875rem);
}

.title {
  margin: 0;
  font-size: $f14;
  user-select: none;
  cursor: pointer;
}
