@import '../../../styles/vars.scss';

.wrapper {
  display: block;
  margin-top: 1.25rem;
}

.label {
  line-height: 1.3;
  color: $secondary;
  margin: 0;
}

.tooltip {
  line-height: 1.3;
  margin: 0.625rem 0 0;
  font-size: $f14;
}

.error {
  color: $red;
  font-size: $f14;
  line-height: 1.3;
  margin: 0.625rem 0 0;
}
