@import '../../../styles/vars.scss';

.content {
  position: fixed;
  z-index: 6;
  top: 0;
  background: $white;
  margin: 0 0 0 auto;
  padding: 0;
  width: 32.875rem;
  height: 100dvh;
  transition: transform 0.2s ease-in-out;
  transform: translate3d(100%, 0, 0);
  border: none;
  overflow: auto;
  color: $primary;

  &--open {
    transform: translate3d(0, 0, 0);
  }

  @media (max-width: $w992) {
    width: 100%;
  }
}

.overlay {
  background: rgba(45, 45, 45, 0.4);
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;
  pointer-events: none;

  &--open {
    background-color: rgba(45, 45, 45, 0.4);
    pointer-events: all;
  }
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 3.125rem 3.75rem 2.5rem 4.375rem;

  @media (max-width: $w992) {
    width: 100%;
    padding: 1.5rem;
  }

  &_full {
    height: 100%;
  }
}

.buttons {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateZ(0);

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
}

.children {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  flex-grow: 1;
}
