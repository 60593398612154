@import 'styles/vars';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.black {
    .line {
      background-color: $white;
    }
  }
  &.white,
  &.light,
  &.fixedTransparent {
    .line {
      background-color: $primary;
    }
  }

  &.color-white {
    span.line {
      background-color: $white;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 2.25rem;
  height: 100%;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;

  @media (max-width: $w992) {
    width: auto;
  }

  &.active {
    .line {
      background-color: $brown;
    }
  }

  &:hover {
    .line {
      width: 1.25rem;
      background-color: $brown;
    }
  }
}

.line {
  width: 1.0625rem;
  height: 0.0625rem;
  transition: $ease-in;

  &:not(:first-child) {
    margin-top: 0.3125rem;
  }

  &.long {
    width: 1.25rem;
  }
}
