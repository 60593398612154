@import '../../../styles/vars.scss';

.form {
  width: 100%;
}

.text {
  margin: 1rem 0 2.5rem;
  color: $secondary;
  font-size: 0.875rem;
}

.action {
  padding: 0;
  background: none;
  border: none;
  color: $primary;
  cursor: pointer;
}
