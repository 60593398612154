@import 'styles/vars';

.container {
  display: flex;
  height: 100%;

  @media (max-width: $w1080) {
    display: none;
  }
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
}

.item {
  list-style: none;
  display: flex;
  margin: 0 1rem 0 0;
  height: 100%;

  &:first-child a {
    padding-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 0.625rem;
  text-decoration: none;

  @media (max-width: $w1400) {
    padding: 0 0.25rem;
  }

  @media (max-width: 81.25rem) {
    padding: 0;
  }

  span {
    font-size: $f20;
    line-height: 1;
    color: $primary;
    text-transform: lowercase;
    transition: color $ease-in;
    position: relative;
    top: -0.1875rem;

    @media (max-width: 81.25rem) {
      font-size: $f18;
    }
  }

  &.black {
    span {
      color: $white;
    }
  }

  &.active {
    span {
      color: $brown;
    }
  }

  &:hover {
    span {
      color: $brown;
    }
  }
}
