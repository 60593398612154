.cover {
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
}

.cover-top {
  object-fit: cover;
  object-position: center top;
  font-family: 'object-fit: cover; object-position: center top;';
}

.cover-left {
  object-fit: cover;
  object-position: left center;
  font-family: 'object-fit: cover; object-position: left center;';
}

.contain {
  object-fit: contain;
  object-position: center;
  font-family: 'object-fit: contain; object-position: center;';
}
