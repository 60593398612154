@import 'mixins/fluid-font-size';
@import 'styles/vars';

.apoc {
  font-family: Apoc LC, 'Apoc LC', Arial, sans-serif;
}

.h1-apoc {
  @include fluid-font-size(2.25rem, 4.5rem, 6.875rem);
  @extend .apoc;
}

.h1,
h1 {
  @include fluid-font-size(2rem, 3rem, 3.75rem);
}

.h2,
h2 {
  @include fluid-font-size(1.625rem, 2.5rem, 3.125rem);
}

.h3,
h3 {
  @include fluid-font-size(1.375rem, 2rem, 2.625rem);
}

.h4,
h4 {
  @include fluid-font-size(1.125rem, 1.625rem, 2.25rem);
}

.h5,
h5,
.h6,
h6 {
  @include fluid-font-size($marg16, 1.375rem, 1.875rem);
}

.text-big {
  @include fluid-font-size(1rem, 1.125rem, 1.25rem);
}

a {
  text-decoration: none;
  transition: color $ease-in;

  &:hover {
    color: $brown;
    transition: $ease-out;
  }
}

.image-line {
  display: flex;
  justify-content: space-between;
  margin-bottom: $marg16;

  @media (max-width: $w992) {
    flex-direction: column;

    & figcaption {
      margin-bottom: $marg24;
    }
  }
}

.image-line img {
  width: 100%;
}

div.ps__rail-y {
  background: transparent !important;

  .ps__thumb-y {
    background: #565656;
  }
}

.content {
  max-width: 45rem;
  margin-left: auto;
  margin-right: 7.5rem;
  color: $primary;

  @media (max-width: $w992) {
    max-width: 100%;
    margin: 0;
  }

  //snippets

  .small-text {
    font-size: $f14;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.5);
    max-width: 80%;

    @media (max-width: $w992) {
      max-width: 100%;
    }
  }

  .big-text {
    font-size: $f20;
    line-height: 1.5;
    margin-bottom: 1.5rem;

    @media (max-width: $w992) {
      font-size: $f18;
    }
  }

  .images-line {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    margin-top: 2.5rem !important;

    img {
      margin: 0;
    }

    figcaption {
      margin-top: 1rem;
    }

    @media (max-width: $w768) {
      grid-template-columns: 100%;
      grid-gap: 1.25rem;
    }
  }

  .text-photo {
    display: flex;
    justify-content: space-between;

    @media (max-width: $w768) {
      flex-direction: column-reverse;
    }

    & > div {
      margin-right: 2rem;

      @media (max-width: $w768) {
        margin-right: 0;
      }
    }

    figure {
      margin: 0;
      max-width: 40%;
      flex-shrink: 0;

      @media (max-width: $w768) {
        max-width: none;
        margin-bottom: 1.5rem;
      }
    }

    img {
      margin: 0;
      width: 100%;
    }
  }

  ol {
    margin: 0;
    margin-left: 1.5rem;
    padding: 0;

    &.steps {
      counter-reset: li;
      padding-top: 1.5rem;

      li {
        position: relative;
        list-style: none;
        margin-bottom: 2.5rem;

        & > *:not(:first-child) {
          margin-top: 1.25rem;
        }

        &::before {
          counter-increment: li;
          content: 'Шаг ' counter(li);
          position: absolute;
          top: -$marg24;
          font-size: 0.875rem;
          line-height: 150%;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $primary;
          opacity: 0.5;
        }
      }
    }

    li {
      font-size: 1.125rem;
      line-height: 1.5;

      &:not(:first-child) {
        margin-top: 1.25rem;
      }

      @media (max-width: $w992) {
        font-size: $marg16;
      }

      figure {
        margin-bottom: 2.5rem;
        margin-top: 2.5rem;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0 0 0 1.625rem;
    list-style: none;
    font-size: $f18;

    @media (max-width: $w992) {
      font-size: $f16;
    }

    li {
      position: relative;
      &:not(:first-child) {
        margin-top: 0.3125rem;
      }

      &::before {
        content: '✧';
        position: absolute;
        left: -1.625rem;
      }
    }
  }

  a {
    color: $brown;
    transition: all $ease-in;

    * {
      color: $brown !important;
    }

    &:hover {
      color: $primary;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1.4;
    color: $primary;
    font-weight: normal;
  }

  h2 {
    font-size: calc(
      2.5rem + 0.25 * ((100vw - 62rem) / #{strip-unit(100rem - 62rem)})
    );
  }
  h3 {
    @include fluid-font-size(1.15rem, 1.4rem, 1.65rem);
  }
  h4 {
    @include fluid-font-size(1.1rem, 1.3rem, 1.55rem);
  }
  h5 {
    @include fluid-font-size(1rem, 1.2rem, 1.45rem);
  }

  h6 {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-size: $f14;
    font-weight: normal;
    margin-top: 1.875rem !important;
    margin-bottom: 0.5rem;

    & + * {
      margin-top: 0 !important;
    }
  }

  p {
    font-size: $f18;
    line-height: 1.5;
    margin: 0;

    @media (max-width: $w992) {
      font-size: $f16;
    }
  }

  figcaption {
    font-size: $f14;
    line-height: 1.5;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $primary;
    opacity: 0.5;
    text-align: center;
  }

  figure {
    margin: 0;
  }

  img {
    display: block;
    margin: 2.5rem auto;
    height: auto;
    max-width: 100%;

    @media (max-width: $w992) {
      width: 100%;
      height: auto;
    }
  }

  iframe {
    width: 100%;
  }

  & > * {
    margin-top: 1rem !important;
  }

  a.product-link span.product-link-icon-wrapper {
    margin: 0;
    margin-left: 0.25rem;
    display: inline-block;
    width: 1.875rem;
    height: 0.625rem;
    position: relative;

    img {
      margin: 0;
      position: absolute;
      top: -0.625rem;
      left: 0;
      height: 1.875rem;
      width: 100%;
      border: 1px solid $gray;
      border-radius: 50%;
    }
  }

  b,
  strong {
    font-weight: 500;
  }
}

.common {
  .structure_accordeon_head.active::after {
    transform: rotate(45deg);
  }
}
