@import '../vars.scss';

.input {
  display: block;
  width: 100%;
  border: 1px solid $dark50t;
  border-radius: 0;
  height: 3.125rem;
  font-size: $f18;
  padding-left: 1rem;
  margin-top: 0.625rem;
  transition: border-color $ease-in;

  &.textarea {
    height: 6.875rem;
    padding: 0.625rem 0 0.625rem 1rem;
    max-width: 100%;
    min-width: 100%;
  }

  &.error {
    border-color: $red;
  }

  &:hover {
    border-color: $dark;
  }

  &:focus,
  &:active {
    outline: none;
    border-color: $dark;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: $primary;
    -webkit-box-shadow: 0 0 0 1000px $white inset;
  }
}
