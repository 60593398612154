@import '../../../../styles/vars.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $primary;
  width: 10rem;
  padding: 0 1.25rem;
  height: 3.25rem;
  font-size: $f20;
  flex-shrink: 0;
}

.small {
  border: none;
  padding: 0;
  height: auto;
  line-height: 1.25rem;
  width: 5rem;
  font-size: $f18;
}

.icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
  background: transparent;
  border: none;
  padding: 0;

  &:focus,
  &:active {
    outline: none;
  }
}

.count {
  flex-grow: 1;
  text-align: center;
}

.small .icon {
  flex-shrink: 0;
  justify-content: center;
  border: 1px solid $primary;
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;

  &:last-child {
    svg {
      transform: translate3d(0, -0.5px, 0);
    }
  }
}
