.dialog {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  padding: 0;
  border: none;
  overflow-y: auto;
}
