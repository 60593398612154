@import 'styles/vars';

.container {
  display: flex;
  align-items: center;
  height: 4.375rem;
  width: 100%;
  background-color: $light;
  overflow-x: auto;

  @media (max-width: $w992) {
    height: 3.5rem;
  }
}

.wrapper {
  height: 100%;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;

  @media (max-width: $w992) {
    justify-content: flex-start;
  }
}

.item {
  list-style: none;
  display: flex;
  margin: 0 0.5rem 0 0;
  height: 100%;
  cursor: pointer;

  @media (max-width: $w992) {
    margin-right: 0.5rem;
  }

  &:first-child a {
    padding-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.link {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 0.375rem;
  text-decoration: none;

  @media (max-width: $w992) {
    padding: 0 0.5rem;
  }

  &.selected {
    span {
      color: $brown;
    }
  }

  &:hover {
    span {
      color: $brown;
    }
  }

  span {
    font-size: $f14;
    line-height: 1;
    color: $primary;
    text-transform: lowercase;
    transition: color $ease-in;
    white-space: nowrap;

    @media (max-width: $w992) {
      font-size: $f16;
    }
  }
}
