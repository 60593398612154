@import '../../styles/vars.scss';

.button {
  display: inline-flex;
  align-items: center;
  border: none;
  background: none;
  color: $brown;
  padding: 1rem 1.25rem;
  cursor: pointer;
  transition: $ease-in;
  user-select: none;
  font-weight: 400;

  &:not(.disabled):hover {
    transition: $ease-out;
    opacity: 0.5;
  }

  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  &.loading {
    padding: 1.1rem 1.25rem;
    pointer-events: none;

    svg {
      width: 2.5rem;
    }

    circle {
      fill: $brown;
    }
  }
}

.children {
  margin-right: 0.625rem;
}
