.wrapper {
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  rect {
    fill: transparent;
  }
}
