@font-face {
  font-family: 'Apoc LC';
  src: url('/fonts/ApocLC-RegularItalic.woff2') format('woff2'),
    url('/fonts/ApocLC-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Apoc LC';
  src: url('/fonts/ApocLC-Regular.woff2') format('woff2'),
    url('/fonts/ApocLC-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Graphik LC Web';
  src: url('/fonts/GraphikLCWeb-Black.woff2') format('woff2'),
    url('/fonts/GraphikLCWeb-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Graphik LC Web';
  src: url('/fonts/GraphikLCWeb-RegularItalic.woff2') format('woff2'),
    url('/fonts/GraphikLCWeb-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: block;
}

@font-face {
  font-family: 'Graphik LC Web';
  src: url('/fonts/GraphikLCWeb-Medium.woff2') format('woff2'),
    url('/fonts/GraphikLCWeb-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Graphik LC Web';
  src: url('/fonts/GraphikLCWeb-Bold.woff2') format('woff2'),
    url('/fonts/GraphikLCWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Graphik LC Web';
  src: url('/fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
